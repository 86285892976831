import React, {useCallback, useMemo} from 'react';
import classNames from "classnames";
import useGlobal from "../../store";

import UserInfoStats from "../../pages/main/infoWrapper/userInfo/UserInfoStats";
import UserInfoHeader from "../../pages/main/infoWrapper/userInfo/UserInfoHeader";
import LogoutConfigButtons from "../../pages/main/infoWrapper/userInfo/LogoutConfigButtons";
import {useHistory} from "react-router-dom";
import useColorMode from "../../hooks/useColorMode";


const UserInfoDetailed = transition => {
    const [{
        ticketInfo: {active},
        userInfo: {
            stats,
            companies,
            companyName
        },
        gradients,
        activeGradient
    }, globalActions] = useGlobal();


    // TO DELETE
    // let gl=useGlobal();
    // console.log(gl[0]);
    // console.log(tickets);
    // console.log(companyName);
    // console.log(stats);
    // console.log(companies);

    const {toggleModal, setActiveCompany, showMessage} = globalActions;
    // const className = classNames('user-info-detailed-wrapper-modal', modalState ? 'toggled' : '');
    const className = classNames('user-info-detailed-wrapper-modal');

    const history = useHistory();

    const selectCompany = useCallback(
        item => {
            if (active) {
                showMessage({
                    type: 'warning',
                    body: "Завершіть активний тікет спочатку"
                });
                return;
            }
            history.push('/main');
            toggleModal();
            setActiveCompany(item);
            localStorage.setItem('activeCompany', JSON.stringify(item, null, 2));
        }, [active, history, setActiveCompany, showMessage, toggleModal]);

    const allCompanies = useMemo(() => {
        return companies.map(item =>
            <h3
                key={item.id}
                onClick={() => selectCompany(item)}
                className='single-company'
                style={companyName === item.name ? {
                    backgroundColor: gradients[activeGradient].accentColor,
                    fontWeight: 600,
                    opacity: 1,
                    color: '#fff'
                } : {}}
            >
                {item.name}
                <span>({item.availableTicketsRemain})</span>
            </h3>
        );
    }, [companies, companyName, selectCompany, activeGradient, gradients]);

    const styles = useColorMode();

    return (
        <>
            <div
                className='user-info-detailed-wrapper'
                onClick={toggleModal}
            />
            <div
                className={className}
                style={{
                    ...styles,
                    transition,
                    boxShadow: `0 0 24px -9px ${gradients[activeGradient].accentColor}`
                }}
            >
                <UserInfoHeader/>
                {companies && <div
                    className='user-info-companies'
                >
                    {allCompanies}
                </div>}
                <UserInfoStats/>
                <div className='date-joined'>
                    <h2>Дата реєстрації: <span>{stats['dateJoined']}</span></h2>
                </div>
                <LogoutConfigButtons/>

            </div>
        </>
    );
};

export default UserInfoDetailed;