import React, {memo, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import useGlobal from "../../../store";
import {useQuery} from "@apollo/react-hooks";
import {path} from "../../../utils/api/apiQueries";
import useColorMode from "../../../hooks/useColorMode";

const compWrapClass = classNames('container', 'general-info-wrapper');

const GeneralInfo = () => {
    const [globalState, globalActions] = useGlobal();
    const {setGeneralStats} = globalActions;
    const {gradients, activeGradient} = globalState;

    const {
        generalStats,
        userInfo: {companyId}
    } = globalState;

    const {data} = useQuery(path.query.getGeneralInfo, {
        variables: {
            companyId
        },
        pollInterval: 15000,
        fetchPolicy: 'network-only',
        skip: !companyId
    });

    useEffect(() => {
        if (data) {
            // console.log("PSt, GOT SOME GENERAL INFO FOR YOU!")
            setGeneralStats(JSON.parse(data.generalInfo));
        }
    }, [data, setGeneralStats]);

    const statsArr = useMemo(() => {
        return Object.keys(generalStats).map((item) => {
            return (
                <div
                    key={item}
                    className='single-stat-wrapper'
                    style={{
                        background: gradients[activeGradient].secondaryContainer,
                    }}
                >
                    <div className='single-stat'>
                        <h1>{item}</h1>
                        <h2>{generalStats[item]}</h2>
                    </div>
                </div>
            )
        })
    }, [generalStats, activeGradient, gradients]);


    const styles = useColorMode();

    return (
        <div
            className={compWrapClass}
            style={{
                ...styles,
                boxShadow: `0 0 24px -9px ${gradients[activeGradient].accentColor}`
            }}
        >
            <h1>Загальна інфо</h1>
            <div className='general-info-wrapper-container'>
                {statsArr.length > 0 && statsArr}
            </div>
        </div>
    );
};
export default memo(GeneralInfo);