import React from 'react';
import UserInfoDetailed from "./modal/UserInfoDetailed";
import useGlobal from "../store";

const Modal = () => {
    const [{modalState}] = useGlobal();

    return modalState ? <UserInfoDetailed/> : null
};

export default Modal;