import {ApolloClient} from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";

const cache = new InMemoryCache();

const apiUrl = 'https://api.outforz.com/graphql/';

const link = new createHttpLink({
    uri: apiUrl,
    credentials: 'include'
});

const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
});

export default client;
