import classNames from 'classnames';
import React, {useEffect} from 'react';

import useGlobal from "../../../store";

import {useLazyQuery} from "@apollo/react-hooks";

import {path} from "../../../utils/api/apiQueries";

import UserInfoHeader from "./userInfo/UserInfoHeader";
import useColorMode from "../../../hooks/useColorMode";


const UserInfo = () => {
    const [globalState, globalActions] = useGlobal();
    const {
        modalState,
        userInfo,
        token,
        userInfo: {id},
        gradients,
        activeGradient,
    } = globalState;
    const {toggleModal, setUserStatistics, setCompanies} = globalActions;
    const className = classNames('container', 'user-info-wrapper');

    const [requestStatistics, {data: newStats, refetch: refetchStats}] = useLazyQuery(path.query.statistics);

    useEffect(() => {
        requestStatistics();
    }, [requestStatistics]);


    const [fetchCompaniesInfo, {loading, error, data, refetch}] = useLazyQuery(path.query.getCompanyInfo2);

    useEffect(() => {
        fetchCompaniesInfo({
            variables: {
                id,
            },
        });
    }, [fetchCompaniesInfo, token, id]);

    useEffect(() => {
        if (!data) return;
        if (data && data.user.company.edges) {
            setCompanies(data.user.company.edges.map(company => company.node));
        }
    }, [data, loading, error, refetch, setCompanies]);


    useEffect(() => {
        if (newStats && newStats.statistics) {
            setUserStatistics(newStats.statistics);
        }
    }, [modalState, newStats, setUserStatistics]);


    const showUserInfo = () => {
        toggleModal();
        refetch();
        refetchStats();
    };

    const styles = useColorMode();

    return (
        <div
            className={className}
            style={{
                ...styles,
                boxShadow: `0 0 24px -9px ${gradients[activeGradient].accentColor}`
            }}
            onClick={() => {
                refetch()
                    .catch();
                showUserInfo();
            }}
        >
            {!modalState && <UserInfoHeader userInfo={userInfo}/>}
        </div>
    );
};
export default UserInfo;