import React from "react";
import useGlobalHook from "use-global-hook";


import {userActions, userState} from "./actions/userActions";
import {ticketActions, ticketState} from "./actions/ticketActions";
import {generalActions, generalState} from "./actions/genaralActions";
import {supervisorState, supervisorActions} from "./actions/supervisorActions";


const initialState = {
    ...userState,
    ...ticketState,
    ...generalState,
    ...supervisorState
};


const actions = {
    ...userActions,
    ...ticketActions,
    ...generalActions,
    ...supervisorActions
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
